import { EventEmitter } from 'fbemitter'

export const EVENT_ALERT = 'onAlert'

let _emitter: EventEmitter | null
const getEmitter = () => {
  if (!_emitter) {
    _emitter = new EventEmitter()
  }
  return _emitter
}

export const emitSuccess = (message: string, detailedMessages?: string[]) => {
  _emitter?.emit(EVENT_ALERT, {
    type: 'success',
    message: message,
    detailedMessages,
  })
}

export const emitError = (message: string, detailedMessages?: string[]) => {
  _emitter?.emit(EVENT_ALERT, {
    type: 'error',
    message: message,
    detailedMessages,
  })
}

export const emitWarning = (message: string, detailedMessages?: string[]) => {
  _emitter?.emit(EVENT_ALERT, {
    type: 'warning',
    message: message,
    detailedMessages,
  })
}

export const emitInfo = (message: string, detailedMessages?: string[]) => {
  _emitter?.emit(EVENT_ALERT, {
    type: 'info',
    message: message,
    detailedMessages,
  })
}

export default getEmitter()
