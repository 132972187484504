import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { ServiceApiFactory } from 'generated/restapi-sc'
import { useRestController } from 'common/hooks/use-rest-controller'
import { emitError } from 'common/utils/event-emitter'

export const useServiceController = () => {
  const { config, query, isReady } = useRestController()
  const { orgid, srvid } = query
  const { getService } = ServiceApiFactory(config)

  const { data: dataService, isLoading: isServiceLoading } = useQuery(
    ['fetch-service', orgid, srvid],
    () => getService(parseInt(orgid as string), parseInt(srvid as string)),
    {
      enabled: isReady && !!orgid && !!srvid,
      onError: (err) => {
        let errMessage = err
        if (axios.isAxiosError(err) && err.response) {
          errMessage = err.response.statusText
        }
        emitError(`Error fetching service details. ${errMessage}`)
      },
    }
  )

  return {
    isLoading: isServiceLoading,
    service: dataService?.data,
  }
}

export type TServiceController = ReturnType<typeof useServiceController>
