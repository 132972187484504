/* tslint:disable */
/* eslint-disable */
/**
 * SC Qgrants
 * SC Qgrants API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CarerInfo } from './carer-info';
// May contain unused imports in some cases
// @ts-ignore
import { ServiceConfig } from './service-config';

/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'street': string | null;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'suburb': string | null;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'postcode': string | null;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'organisation_id': number;
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'location_id': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Service
     */
    'op_days': Array<ServiceOpDaysEnum>;
    /**
     * 
     * @type {CarerInfo}
     * @memberof Service
     */
    'carer': CarerInfo;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'status': string | null;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'product': string | null;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'engine': string | null;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'opstatus': string;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'new_ccs_dest_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'mobile': string | null;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'website_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof Service
     */
    'abn': string;
    /**
     * 
     * @type {ServiceConfig}
     * @memberof Service
     */
    'srv_config': ServiceConfig | null;
}

export const ServiceOpDaysEnum = {
    Mon: 'MON',
    Tue: 'TUE',
    Wed: 'WED',
    Thu: 'THU',
    Fri: 'FRI',
    Sat: 'SAT',
    Sun: 'SUN'
} as const;

export type ServiceOpDaysEnum = typeof ServiceOpDaysEnum[keyof typeof ServiceOpDaysEnum];


