import { useRouter } from 'next/router'
import { useRestConfig } from './use-rest-config'

export const useRestController = () => {
  const { query, isReady: isRouterReady, push } = useRouter()
  const { config, isConfigReady: isConfigReady } = useRestConfig()

  return {
    isReady: isRouterReady && isConfigReady,
    config,
    query,
    push,
  }
}
