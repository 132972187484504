import { useQueries, useQuery } from '@tanstack/react-query'
import { addDays, addYears, format, getDay, startOfDay, subYears } from 'date-fns'
import { getSdk } from 'generated/graphql/schema'
import { useApiClient } from 'services/use-api-client'
import { emitError } from 'common/utils/event-emitter'
import { TZ_DATE } from 'common/utils/date-utils'
import { AttendanceApiFactory } from 'generated/restapi-sc'

export const childrenKeys = {
  'enrolments': (srvId?: string) => ['fetch-sc-enrolments', srvId],
  'attendance': (srvId?: string, dateStart?: Date) => ['fetch-sc-attendance', srvId, dateStart],
}

export const ChildrenController = () => {
  const { gqlClient, restConfig, isReady } = useApiClient()
  const { getSmartCentralChildEnrolments } = getSdk(gqlClient)
  const { getAttRecord } = AttendanceApiFactory(restConfig)

  const GetSCEnrolledChildren = (srvId?: string) => {
    const queryKey = childrenKeys.enrolments(srvId)
    const query = useQuery(
      queryKey,
      () =>
        getSmartCentralChildEnrolments({
          srvId: srvId as string,
          startDate: format(subYears(new Date(), 1), TZ_DATE),
          endDate: format(addYears(new Date(), 1), TZ_DATE),
        }),
      {
        enabled: isReady && !!srvId,
        select: (data) => data.service.enrolments,
        onError: (err) => {
          emitError(`Error fetching enrolments details. ${err}`)
        },
      }
    )

    return { queryKey, ...query }
  }

  const GetSCChildrenAttendances = (srvId?: string, dateStart?: Date, weeks?: number) => {
    let results = []
    let dates: Date[] = []
    let dateStepper = dateStart

    if (dateStepper && weeks) {
      for (let i = 0; i < weeks * 7; i++) {
        if (getDay(dateStepper) !== 0 && getDay(dateStepper) !== 6) {
          dates.push(dateStepper)
        }
        dateStepper = addDays(dateStepper, 1)
      }
    }

    results = useQueries({
      queries: dates.map((date) => ({
        queryKey: childrenKeys.attendance(srvId, startOfDay(date)),
        enabled: !!srvId && dates.length > 0,
        refetchOnWindowFocus: false,
        queryFn: () => getAttRecord(parseInt(srvId as string), format(date, TZ_DATE)),
      })),
    })

    return { attendances: results, isLoading: results.some((result) => result.isLoading), dates }
  }

  return { GetSCEnrolledChildren, GetSCChildrenAttendances }
}
