import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { emitError } from 'common/utils/event-emitter'

const QGRANTS_TOKEN = '/api/token'

interface IQGrantToken {
  appKey?: string
  appSecret?: string
}

export const useQGrantToken = (props: IQGrantToken) => {
  const { appKey, appSecret } = props

  const { data: tokenData, isLoading: isTokenLoading } = useQuery(
    ['fetch-qgrants-token', appKey],
    () =>
      axios.get(`${QGRANTS_TOKEN}`, {
        headers: { appkey: appKey, appsecret: appSecret },
      }),
    {
      onError: (err) => {
        emitError(`Failed to fetch QGrant token. ${err}`)
      },
      enabled: !!appKey && !!appSecret,
      refetchInterval: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    }
  )

  return {
    accessToken: tokenData?.data?.access_token,
  }
}
