/* tslint:disable */
/* eslint-disable */
/**
 * SC Qgrants
 * SC Qgrants API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const SessionTypes = {
    Hrs: 'Hrs',
    HrsAbsence: 'Hrs Absence',
    HrsAdditionalAbsence: 'Hrs Additional Absence',
    CessationOfCare: 'Cessation of Care'
} as const;

export type SessionTypes = typeof SessionTypes[keyof typeof SessionTypes];



