/* tslint:disable */
/* eslint-disable */
/**
 * SC Qgrants
 * SC Qgrants API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const IsEnabled = {
    _0: '0',
    _1: '1'
} as const;

export type IsEnabled = typeof IsEnabled[keyof typeof IsEnabled];



